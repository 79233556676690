.meta-search-result {
  .web-page-link {
    &:first-of-type {
      border-top: 0;
      //margin-top: 5px;
     // border-bottom: 0;

     }

    &.card {
      border-top: 0;
      //border-bottom: 0;

      //padding-left: 30px;
     // padding-top: 1rem;

      .card-body {
        padding-left: 1.5rem;
        padding-top: 1rem;
        padding-bottom: 1rem;
      }
    }

    a, a:hover {
      text-decoration: none;
    }

    .card-body {
     // padding: 0.75rem;
      padding: 12px;
      background: #FFF;
      border-top : 0;
      border-bottom : 0;
      //
      //&:hover {
      //  background: rgba(0, 0, 0, 0.04);
      //}
    }

    .result-title {
      margin-bottom: 0.25rem;
      font-size: 18.864px;
      font-weight: 400;
      line-height: 23.0141px;
      //color: #333;
      color: rgb(0, 39, 142);


    }

    .result-url {
      font-size: 14.4px;
      color: rgb(32, 105, 43);
      font-weight: 400;
      line-height: 23.04px;
      //color: #46A2F2;
      margin-bottom: 0.25rem;
    }

    .result-desc {
      font-size: 14.4px;
      font-weight: 400;
      line-height: 20.88px;
      color: rgb(73, 73, 73);
      margin-bottom: 0.25rem;
    }

    .btn-toggle-desc {
      color: #AAA;
    }
  }
}
