.cart-page {

  .divider {
    height: 1px;
    width: 100%;
    background: #DEDEDE;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .txt-strike {
    text-decoration-line: line-through;
  }

  .txt-bold {
    font-weight: bold;
    font-size: 1rem;
  }

  .txt-product-qty {
    font-size: 1rem;
    font-weight: bold;
  }

  .txt-small {
    font-size: 80%;

  }

  .txt-green {
    color : #60b044;
    font-weight: bold;
  }
}
