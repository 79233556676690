.dashboard-page {
  .search-form-container {
    //margin-top: 1rem;
    background-color: #e7edff;

    position: fixed;
    width: 100%;
    top: 0;
    z-index: 5;

    padding-top: 0.5rem;
    padding-bottom: 0.5rem;

    margin-bottom: 1.2rem;

    -webkit-box-shadow: 0px 7px 25px -8px rgba(0,0,0,0.20);
    -moz-box-shadow: 0px 7px 25px -8px rgba(0,0,0,0.20);
    box-shadow: 0px 7px 25px -8px rgba(0,0,0,0.20);
  }

  .dashboard-widgets-container {
    //max-height: calc(100vh - 130px);
    //overflow-x: hidden;
    //overflow-y: auto;
    margin-top: 90px;

    padding-bottom: 1rem;
    min-height: calc(100vh - 129px);
  }
}
