.products-list-result {

  .txt-strike {
    text-decoration-line: line-through;
  }

  .txt-small {
    font-size: 80%;

  }

  .txt-bold {
    font-weight: bold;
    font-size: 1rem;
  }

  img {
    max-width: 100%;
    height: auto;
    max-height: 100px;

  }
}
