footer.footer {
  width: 100%;
  font-size: 12px;
  color: #555;

  -webkit-box-shadow: 0px -9px 9px -12px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px -9px 9px -12px rgba(0,0,0,0.75);
  box-shadow: 0px -9px 9px -12px rgba(0,0,0,0.75);

  //background: rgba(220, 255, 170, 0.6);
  background: #dbe1f2;
  padding: 0.5rem 0;

  a {
    font-size: 12px;
    color: #444;
  }

  .left-menu {
    .dropdown-item {
      padding: 0.25rem 0.75rem;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .flag-container {
    img {
      width: 15px;
      height: auto;
      vertical-align: baseline;
    }
  }
}
