@import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
@import '../../node_modules/font-awesome/css/font-awesome.min.css';
@import '../../node_modules/react-bootstrap-typeahead/css/Typeahead.css';
@import '../../node_modules/react-quill/dist/quill.snow.css';
@import '../../node_modules/react-calendar/dist/Calendar.css';
@import '../../node_modules/react-clock/dist/Clock.css';

@import "footer";
@import "search-form";
@import "home-page";
@import "edit-page";
@import "dashboard-page";
@import "search-result-page";
@import "settings";
@import "about-page";
@import "custom-page";
@import "profile-page";

@import "dashboard-page/digital-clock";
@import "search-results/blog";
@import "search-results/dictionary-word";
@import "search-results/person";
@import "search-results/dictionary";
@import "search-results/fixed-text";
@import "search-results/meta-search";
@import "search-results/files";
@import "search-results/company";
@import "search-results/place";
@import "search-results/movie";
@import "search-results/calculator";
@import "search-results/post-timeline";
@import "search-results/product";
@import "search-results/products-list";
@import "search-results/video";
@import "themes/dropdown-style-1";
@import "landing-page";
@import "file-manager";
@import "cart-page";
@import "activity-home-page";
@import "themes/snow";
@import "themes/pagination-style-1";
@import "pdf-container";

/* Variables */
$vin-blue: #5bb7db;
$vin-green: #60b044;
$vin-red: #ff0000;

body {
  //background: rgba(0, 0, 255, 0.04);
  background-color: #e7edff;
}

.pointer.link {
  cursor: pointer;
}

.img-responsive {
  max-width: 100%;
}

blockquote {
  background: #f9f9f9;
  border-left: 10px solid #ccc;
  margin: 0.5em 10px 1.5em 10px;
  padding: 0.5em 10px;
  quotes: "\201C""\201D""\2018""\2019";
}

blockquote:before {
  color: #ccc;
  content: open-quote;
  font-size: 4em;
  line-height: 0.1em;
  margin-right: 0.25em;
  vertical-align: -0.4em;
}

blockquote p {
  display: inline;
}

.privacy-page {
  .privacy-details-container {
    h4, p, a, div {
      font-family: serif;
    }
  }
}

.dropzone-container {
  .dropzone-view {
    border-radius: 7px;
    background: rgba(211, 229, 244, 0.5);
    border: 1px dashed #777;
    text-align: center;

    padding: 2rem;
  }

  img {
    max-width: 100%;
    height: auto;
  }

  @media (max-width: 768px) {
    .dropzone {
      margin-bottom: 1rem;
    }
  }
}
