.files-result {
  .react-pdf__Document {
    max-width: 100%;

    canvas {
      max-width: 100%;
    }
  }
}
.file-preview-modal {
  max-width: 720px;
  width: 95%;
}

.file-preview-photo {
  img {
    width:500px;
    height: auto;
  }
}

.file-preview-video {
  .video-player > div {
    max-width: 100%;
  }
}
