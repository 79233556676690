.post-timeline-container {
  .time {
    color: #999;
    font-size: 0.9rem;
    padding-left : 0;
    padding-right: 0;

    margin-bottom: 0;
  }

  img {
    max-width: 100%;
    height: auto;
    max-height: 300px;

  }

  .post-container {
    .image {
      vertical-align: middle;
      width: 40px;
      height: 40px;
      border-radius: 50%;
    }

  }

  .two-photoview-container {
    img {
      max-width: 100%;
      height: auto;
      max-height: 80%;
    }
  }
}


