.activity-home-page {

  .search-form-container {
    //margin-top: 1rem;
    background-color: rgba(220, 220, 220, 0.2);

    position: fixed;
    width: 100%;
    top: 0;
    z-index: 5;

    padding-top: 0.5rem;
    padding-bottom: 0.5rem;

    margin-bottom: 1.2rem;

    -webkit-box-shadow: 0px 7px 25px -8px rgba(0,0,0,0.20);
    -moz-box-shadow: 0px 7px 25px -8px rgba(0,0,0,0.20);
    box-shadow: 0px 7px 25px -8px rgba(0,0,0,0.20);
  }

  .divider {
    height: 1px;
    width: 100%;
    background: #DEDEDE;
  }

  .txt-small {
    font-size: 80%;

  }

  .activity-container {
    margin-top: 5rem;

  }

  footer.footer {
    position: fixed;
    bottom: 0;
  }

}
