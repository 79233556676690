.movie-result {
  .images-container {
    img {
      max-width: 100%;
      height: auto;
    }
  }

  .news-container {
    img {
      max-width: 100%;
      height: auto;
    }

  }
}
