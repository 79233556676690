.calculator {
  padding: 0;
  max-width: 634px;
  width: 100%;

  .problem-str {
    font-size: 1rem;
    color: #999;
    text-align: right;
  }

  .result-str {
    font-size: 2rem;
    color: #000;
    text-align: right;
  }

  .bg-white .calculator-button {
    background-color: white;
  }
  .bg-gray .calculator-button {
    background-color: #EFEFEF
  }
  .bg-yellow  .calculator-button  {
    background-color: gold;
  }

  .text-light  .calculator-button {
    font-weight: 300;
  }

  .calculator-button {
    width: 79px;
    height: 60px;

    background-color: #FFF;
    color: #000;
    border-left: 0.5px solid #BBB;
    border-top: 0.5px solid #BBB;
    border-right: 0;
    border-bottom: 0;
    border-radius: 0;
    font-size : 24px;

    outline: none;

    &:hover {
      background-color: #AAA;
    }

    &:hover, &:active, &:visited {
      outline: none;
    }
  }

  .double-span .calculator-button {
    width: 158px;
  }

  .search-form.calculator-result-search-form input {
    font-size: 2rem;
    text-align: right;
    border-color: rgba(0, 0, 0, 0.8);
  }

}
