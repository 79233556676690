.place-result {
  .basic-details {
    p {
      margin-bottom: 0;
      padding-bottom: 8px;
      // font-size: 80%;

    }
  }

  .images-container {
    .image-container {
      margin-bottom: 1rem;
    }

    img {
      max-width: 100%;
      height: auto;
    }
  }

  .news-container {
    img {
      max-width: 100%;
      height: auto;
    }

  }
}
