.edit-page {
  //padding-bottom: 50px;

  & > .container-fluid {
    padding-bottom: 1rem;
    min-height: calc(100vh - 50px);
  }

  .search-form-container {
    margin-top: 1rem;
  }

  .helper-text {
    font-size: 80%;
  }

  .quill.fixed-text-editor .ql-editor {
    min-height: 400px;
  }

  .quill.blog-article-editor .ql-editor {
    min-height: 400px;
  }

  .quill.blog-excerpt-editor .ql-editor {
    min-height: 100px;
  }

  .quill.meanings-editor .ql-editor,
  .quill.synonyms-editor .ql-editor,
  .quill.antonyms-editor .ql-editor {
    min-height: 100px;
  }
}

.edit-result-modal {
  max-width: 95%;
  width: 95%;

  .modal-body {
    padding: 0;
  }
}
.photo-result-modal {
  max-width: 95%;
  width: 95%;
    img {
    width: 100%;
    height: 100%;

  }
}
