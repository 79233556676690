.search-form {
  .search-logo {
    max-width: 100%;
    max-height: 52px;
    height: auto;
    border-radius: 50%;

    -webkit-box-shadow: 0px 3px 6px 0px rgba(50, 49, 50, 0.84);
    -moz-box-shadow:    0px 3px 6px 0px rgba(50, 49, 50, 0.84);
    box-shadow:         0px 3px 6px 0px rgba(50, 49, 50, 0.84);
  }

  .rbt-menu.dropdown-menu {
    padding-top: 0;
    padding-bottom: 0;
    margin-top: -2px;
  }

  .rbt-menu > .dropdown-item {
    padding: 0.5rem 1rem;
    border-bottom: 1px solid #CCC;

    &:last-of-type {
      border-bottom: 0;
    }
  }

  .suggestion-row {
    .text-small {
      font-size: 85%;
    }

    img.suggestion-img {
      width: 29px;
      height: 29px;
      border: 1px solid #DEDEDE;
      border-radius: 50%;
    }


  }

}
