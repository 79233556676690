.person-result {
  .basic-details {
    img.display-pic {
      max-height: 80px;
      width: auto !important;
    }
  }

  .images-container {
    .image-container {
      margin-bottom: 1rem;
    }

    img {
      max-width: 100%;
      height: auto;
    }
  }

  .news-post {
    .card-body {
      padding: 0.75rem;
    }

    img {
      max-width: 100%;
      height: auto;
      max-height: 150px;
    }

    p {
      margin-bottom: 0;
      font-size: 80%;
    }
  }

  .txt-small {
    font-size: 80%;
    margin-bottom:0;

  }
  .txt-underline {
    text-decoration: underline;

  }

  .notes-body {
    padding-right: 10px;
    padding-left: 10px;
    padding-top: 10px;

  }

  .social-profiles {
    p {
      font-size: 9px;
      padding-right: 0;
    }


    .fa-youtube {
      color: red;
      font-size: 22px;
    }

    .fa-instagram {
      color: orangered;
      font-size: 22px;
    }

    .fa-facebook {
      color: darkblue;
      font-size: 22px;
    }

    .fa-twitter {
      color: deepskyblue;
      font-size: 22px;
    }

    .fa-wikipedia-w {
      color: black;
      font-size: 17px;
    }
  }
}








