.video-result {
  .card-body {
    .react-player {
      width: 640px;
      height: 480px;

      @media (max-width: 767px) {
        width: 100% !important;
        height: 100% !important;
      }
    }
  }
}
