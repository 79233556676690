.custom-dropdown-style1 {
  .dropdown-menu {
    border: 1px solid #9c9cff;
    border-radius: 5px;
    box-shadow: 0px 7px 25px -3px rgba(0, 0, 0, 0.20);
  }

  .dropdown-item {
    border: 1px solid #CCC;
    font-size: 90%;
    padding: 0.25rem 0.55rem;
    border-radius: 0;

    i {
      font-size: 80%;
    }

    &:first-of-type {
      border-top-right-radius: 5px;
      border-top-left-radius: 5px;
    }

    &:last-of-type {
      border-bottom-right-radius: 5px;
      border-bottom-left-radius: 5px;
    }

    &:hover {
      background: #EFEFEF;
    }
  }

  .border-danger {
    border: 1px solid #ff9c9c;

    &:hover {
      border: 1px solid #ff9c9c;
      background: #ff9c9c;
    }
  }
}
