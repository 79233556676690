.home-page {
  .search-form-container {
    height: 70vh;
  }

  .search-form {
    .search-logo {
      max-width: 100%;
      max-height: 125px;
      height: auto;
      border-radius: 50%;

      -webkit-box-shadow: 0px 3px 12px 0px rgba(50, 49, 50, 0.84);
      -moz-box-shadow:    0px 3px 12px 0px rgba(50, 49, 50, 0.84);
      box-shadow:         0px 3px 12px 0px rgba(50, 49, 50, 0.84);
    }

    .app-title {
      margin-top: 1rem;
      color: #757575;
      font-size: 2.25rem;

      text-shadow: 2px 2px 6px rgba(150, 148, 150, 0.89);
    }

    input.rbt-input {
      padding: 1.5rem 1rem;
      width: 35rem;

      @media (max-width: 767px) {
        width: 25rem;
      }

      @media (max-width: 575px) {
        width: 19.5rem;
      }

      @media (max-width: 400px) {
        width: 100%;
      }
    }

    .input-group-append button {
      padding-right: 1rem;
      padding-left: 1rem;
    }
  }

  .tagline-container {
    font-size: 80%;
    color: #444;
  }

  .download-extension-alert {
    background: #ebfcd4;
    border: 1px solid #d9fda7;
    border-radius: 10px;
    padding: 1rem;
    font-size: 90%;

    position: fixed;
    bottom: 3rem;
    left: 1rem;
  }

  footer.footer {
    position: fixed;
    bottom: 0;
  }

  .privacy-status-container {
    .badge {
      font-size: 0.9rem;
      min-width: 75px;
      width: auto;
      height: 25px;
      text-align: center;
      line-height: 1rem;
    }
  }

  .unsecure-warning {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;

    padding: 0.5rem 0;

    background: #ff9c9c;
    color: #333;
    text-align: center;

    -webkit-box-shadow: 0px 10px 16px -10px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 10px 16px -10px rgba(0,0,0,0.75);
    box-shadow: 0px 10px 16px -10px rgba(0,0,0,0.75);
  }
}
