.fixed-text-result {
  //Put markdown container styles here

  .fixed-text-container {
    pre {
      background: #EFEFEF;
      padding: 1rem;
    }
  }
}
