.custom-page {
  & > .container {
    //max-height: calc(100vh - 130px);
    //overflow-x: hidden;
    //overflow-y: auto;

    padding-bottom: 1rem;
    min-height: calc(100vh - 122px);
  }

  .logo {
    width: 52px;
    height: 52px;
    border-radius: 50%;

    -webkit-box-shadow: 0px 3px 6px 0px rgba(50, 49, 50, 0.84);
    -moz-box-shadow:    0px 3px 6px 0px rgba(50, 49, 50, 0.84);
    box-shadow:         0px 3px 6px 0px rgba(50, 49, 50, 0.84);
  }

  .custom-page-header {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;

    margin-bottom: 1.2rem;

    -webkit-box-shadow: 0px 7px 25px -8px rgba(0,0,0,0.20);
    -moz-box-shadow: 0px 7px 25px -8px rgba(0,0,0,0.20);
    box-shadow: 0px 7px 25px -8px rgba(0,0,0,0.20);

    h4 {
      text-shadow: 2px 2px 6px rgba(150, 148, 150, 0.89);
    }
  }
}
