.search-result-page {
  .search-form-container {
    //margin-top: 1rem;
    background-color: #e7edff;

    position: fixed;
    width: 100%;
    top: 0;
    z-index: 5;

    padding-top: 0.5rem;
    padding-bottom: 0.5rem;

    margin-bottom: 1.2rem;

    -webkit-box-shadow: 0px 7px 25px -8px rgba(0,0,0,0.20);
    -moz-box-shadow: 0px 7px 25px -8px rgba(0,0,0,0.20);
    box-shadow: 0px 7px 25px -8px rgba(0,0,0,0.20);
  }

  .search-result-container {
    //max-height: calc(100vh - 130px);
    //overflow-x: hidden;
    //overflow-y: auto;
    margin-top: 90px;
    padding-bottom: 1rem;
    min-height: calc(100vh - 129px);
  }

  .privacy-status-container {
    .badge {
      font-size: 0.9rem;
      min-width: 75px;
      width: auto;
      height: 25px;
      text-align: center;
      line-height: 1rem;
    }
  }

  .photos-container {
    img {
      width: 100%;
      height: 100%;

    }
  }

  .result-updated-dt {
    color: #999;
    font-size: 0.8rem;

    span {
      color: #777;
    }
  }

}
