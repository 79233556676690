.landing-page {
  .hero {
    background-image: url(../assets/background.svg);
    background-size: cover;
    background-repeat: no-repeat;

    min-height: 100vh;

    .header {
      img {
        width: 60px;
        height: 60px;
        margin-left: 10px;
        margin-top: 20px;
      }
    }

    .hero-content {
      padding-top: 3rem;
    }

    .hero-button {
      display: inline-flex;
      border-radius: 25px;
      background-color: #4495d4;
      justify-content: center;
      color: white;
      height: 30px;
      padding-right: 10px;
      padding-left: 10px;
    }

    .fa-bars {
      color: black;
    }

  }

  .section {
    background-size: cover;
    background-color: black;
    color: white;


    .section-button {
      display: inline-flex;
      height: 42px;
      color: white;
      border-radius: 25px;
      background-color: black;

    }

    .section-number {
      background-color: red;
      border: none;
      color: white;
      font-weight: bold;
      padding: 12px 18px;
      text-align: center;
      text-decoration: none;
      display: inline-block;
      font-size: 1rem;
      margin: 2px 2px;
      border-radius: 50%;
    }
  }

  .section-features {
    padding-top: 5rem;
    padding-bottom: 5rem;
    //min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .hero-marketing {
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
   //background-size: cover;

    min-height: 100vh;
    a {
      color: black;
    }


  }

  .hero-footer {
    padding-top: 2rem;
    background-size: cover;
    background-color:#34383b;
    color: white;

    p {
      margin-top: 0;
      margin-bottom: 0;
      font-size: 14px;
    }

    .footer {
      padding-top: 20px;
      padding-bottom: 20px;

    }
    a {
      color: white;
    }

  }
}
