.blog-article-result {
  .divider {
    height: 1px;
    width: 100%;
    background: #DEDEDE;
  }

  .title {
    font-size: 2.1rem;
  }

  .excerpt {
    font-size: 1.25rem;
  }

  .meta-info {
    font-size: 0.8rem;
  }

  p {
    margin-bottom: 1rem;
  }

  .article-body p {
    font-size: 1.25rem;
  }

  .featured-image {
    width: 100%;
    max-width: 100%;
    height: auto;
  }

  .caption {
    background: #DEDEDE;
    color: #555;
    font-size: 0.9rem;
    padding: 0.2rem 0.5rem;
  }

  .react-share {
    vertical-align: top;
    display: inline-block;
    margin-right: 10px;
    text-align: center;
  }

  .react-share__share-button {
    cursor: pointer;
  }

  .react-share__share-button:hover:not(:active) {
    opacity: 0.75;
  }

  .react-share__custom-icon {
    width: 32px;
    height: 32px;
  }
}
