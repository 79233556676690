$blue1: #E0FFFF;
$blue2: #4169E1;

$yellow: #e1c340;
$lt-yellow: #f8ea8c;

$green1: #a4e8e0;
$green3: #4cd7d0;

$rounded-radius: 10px;

body.snow-theme {
  background-blend-mode: luminosity;

  .home-page .tagline-container {
    font-size: 80%;
    //color: #111;
    color: #B3C7D6FF;
    font-weight: bold;
  }

  .home-page .search-form .app-title {
    // color: #347d7d;
    color: #2460A7FF;
    font-size: 3.25rem;
  }

  .search-form .search-logo {
    border: 1px solid $blue2;
  }

  .search-form input {
    border-radius: 40px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    /* font-size: 0.8rem; */
    border: 1px solid $blue2;
  }

  button {
    border-radius: $rounded-radius * 2;
  }

  .search-form .input-group-append:last-of-type .btn:last-of-type {
    border-radius: 0 50px 50px 0;
  }

  .card {
    border: 1px solid $blue2;
    border-radius: $rounded-radius;
  }

  .modal .modal-content {
    border-radius: $rounded-radius;
  }

  .modal-header {
    border-bottom-color: $blue1;
  }

  .card-header {
    border-color: $blue1;
    background: $blue1;
    border-radius: $rounded-radius;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom-color: $blue2;
  }

  .card-footer {
    border-color: $blue1;
    background: $blue1;
    border-radius: $rounded-radius;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-top-color: $blue2;
  }

  .react-calendar {
    border: 1px solid $blue1;
  }

  .react-calendar__navigation button {
    background: burlywood;
  }

  .react-calendar button {
    background: blanchedalmond;
  }

  .dropdown-menu {
    background: #E1EBEE;
    color: darkgray;
  }

  .dropdown-menu .dropdown-item {
    color: darkblue;
  }

  input, select, textarea {
    border: 1px solid $blue2;
  }

  .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    color: white;
    background-color: $blue2;
    border-color: #dee2e6 #dee2e6 #fff;

    border-radius: 12.5px 12.5px 0 0;

    i {
      color: white;
    }
  }

  .meta-search-result {
    .tab-pane > .card {
      border-top: 1px solid $blue2;
    }

    .card {
      background: transparent;
    }

    .web-page-link .result-title .result.url .result-desc {
      font-family: Tahoma;
    }
  }

  .settings-page {
    .tab-pane > .card {
      border-top: 1px solid $blue2;
    }
  }

}
