.pdf-container {

  .txt-small {
    font-size: 80%;
    margin-bottom: 0;
    padding-right: 5px;
    padding-left: 5px;

  }

  .mobile-view {

    .pagination a {
      font-size: 8px;
      padding-left: 4px;
      padding-right: 4px;

    }

    .txt2-small {
      font-size: 60%;
      margin-bottom: 0;
      padding-right: 2px;
      padding-left: 2px;
    }
  }
}

