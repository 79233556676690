.company-result {
  .company-details {
    img.display-pic {
      max-height: 80px;
      width: auto !important;
    }
  }

  .social-profiles {
    p {
      font-size: 9px;
    }
  }
    .fa-youtube {
      color: red;
      font-size: 22px;

    }

    .fa-instagram {
      color: orangered;
      font-size: 22px;
    }

    .fa-facebook {
      color: darkblue;
      font-size: 22px;
    }

    .fa-twitter {
      color: deepskyblue;
      font-size: 22px;
    }

    .fa-wikipedia-w {
      color: black;
      font-size: 17px;
    }

}
