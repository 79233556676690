.dictionary-word-result {
  .word-meaning {
    font-size: 1.5rem;
  }

  p {
    margin-bottom: 0;
  }
}

.dictionary-prefix-result {
  p {
    margin-bottom: 0;
  }
}

