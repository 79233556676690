.product-result {

  .divider {
    height: 1px;
    width: 100%;
    background: #DEDEDE;
  }

  .txt-strike {
    text-decoration-line: line-through;
  }

  .txt-small {
    font-size: 80%;

  }

  .txt-bold {
    font-weight: bold;
    font-size: 1rem;
  }

}





